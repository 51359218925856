import axios from "axios";

export const fetchCategories = async (locationID) => {
  let { data } = await axios.get(
    `frontend/categories?location_id=${locationID}`
  );
  if (data) {
    data = data.map((item) => {
      return {
        ...item,
        active: false,
        tag: item.relationships.tags[0],
        keywords: item.keywords.split(","),
        locations:
          item.relationships && item.relationships.locations
            ? item.relationships.locations.map(
                (location) => location.location_id
              )
            : [],
        image:
          item.relationships && item.relationships.image
            ? item.relationships.image.path
            : null,
      };
    });
    data.sort(function (a, b) {
      if (a.order < b.order) return -1;
      if (a.order > b.order) return 1;
      return 0;
    });
    return data;
  }
};
