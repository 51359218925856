<template>
  <div class="homepage">
    <Categories :categories="categories"></Categories>
    <hero-section :slider="slider"></hero-section>
    <categories-cards :categories="categories"></categories-cards>
    <map-section></map-section>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import Categories from "./components/Categories.vue";
import HeroSection from "./components/Hero.vue";
import MapSection from "./components/Map.vue";
import CategoriesCards from "./components/CategoriesCards.vue";
import { fetchCategories } from "@/services/Categories";
import { fetchSlider } from "@/services/Slider";

export default {
  components: { Categories, HeroSection, MapSection, CategoriesCards },

  data: () => ({
    screenWidth: window.innerWidth,
    slider: [],
    categories: [],
  }),
  computed: {
    ...mapGetters(["translate"]),
    locationID() {
      return (
        localStorage.getItem("locationID") || this.$store.getters["locationID"]
      );
    },
  },
  methods: {
    onResize() {
      this.screenWidth = window.innerWidth;
    },
  },
  async created() {
    this.slider = await fetchSlider(this.locationID);
    console.log('slider', this.slider)
    this.categories = await fetchCategories(this.locationID);
  },

  mounted() {
    this.$nextTick(() => {
      window.addEventListener("resize", this.onResize);
    });
  },

  beforeDestroy() {
    window.removeEventListener("resize", this.onResize);
  },
};
</script>

<style lang="scss">
.homepage {
}
</style>
