import axios from "axios";

export const fetchSlider = async (locationID) => {
  let { data } = await axios.get(
    `frontend/sliders/${locationID}`
  );
  if (data) {
    let dataDesktop = 
     data[0].map((item) => {
      return {
        ...item,
        image: item.path,
      };
    });
    let dataMobile = data[1].map((item) => {
      return {
        id: item.id,
        image_phone: item.path,
      };
    });
    const resAll = dataDesktop.map(t1 => ({...t1, ...dataMobile.find(t2 => t2.id === t1.id)}))
    resAll.sort(function (a, b) {
      if (a.order < b.order) return -1;
      if (a.order > b.order) return 1;
      return 0;
    });
    return resAll;
  }
};
