<template>
  <div
    class="home-categories mx-auto"
    id="categories"
    :style="{background: settings[4].value}"
    v-if="categories.length > 0 "

  >
    <div class="max-width">
      <!-- icons -->
      <slick-carousel v-bind="settingsSlider">
        <div
          class="text-center cursor-pointer category-img"
          v-for="(category, i) in categoriesAvailable"
          :key="i"
          @click="categoryClicked(category)"
        >
          <v-skeleton-loader v-if="loading" class="mx-4" type="text">
          </v-skeleton-loader>

          <span
            v-if="!loading"
            :style="{color: settings[5].value, fontSize: settings[6].value+'px'}"
            >{{ category.name }}</span
          >
        </div>
        <template #prevArrow>
          <img
            class="custom-slick-arrow custom-slick-arrow--prev"
            :src="require('@/assets/images/icons/arrow-left-circle.svg')"
            alt="icon"
          />
        </template>
        <template #nextArrow>
          <img
            class="custom-slick-arrow custom-slick-arrow--next"
            :src="require('@/assets/images/icons/arrow-right-circle.svg')"
            alt="icon"
          />
        </template>
      </slick-carousel>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
export default {
  props: {
    categories: {
      type: Array,
      default: () => [],
    },
  },
  data: () => ({
    settingsSlider: {
      centerMode: true,
      slidesToShow: 6,
      slidesToScroll: 3,
      dots: false,
      rtl: localStorage.getItem("language") == "ar" ? true : false,
      responsive: [
        {
          breakpoint: 1024,
          settings: {
            centerMode: false,
            slidesToShow: 4,
            slidesToScroll: 4,
            infinite: true,
          },
        },
        {
          breakpoint: 800,
          settings: {
            centerMode: false,
            autoplay: true,
            autoplaySpeed: 3000,
            slidesToShow: 3,
            slidesToScroll: 1,
            infinite: true,
          },
        },
        {
          breakpoint: 600,
          settings: {
            centerMode: false,
            slidesToShow: 2,
            slidesToScroll: 1,
            infinite: true,
          },
        },
      ],
    },
    loading: false,
    keyUpdated: false,
  }),
  computed:{
    ...mapGetters(['settings']),
    categoriesAvailable(){
      return this.categories.filter(cat => cat.is_active)
    }
  },
  methods: {
    categoryClicked(category) {
      this.$router.push("/category/" + category.slug);
    },
    checkActiveCategory() {
      //   this.categories.forEach((category) => {
      //     if (this.$route.query.theme == category.slug) {
      //       category.active = true;
      //     } else {
      //       category.active = false;
      //     }
      //   });
      //   this.keyUpdated = !this.keyUpdated;
    },
    async getData() {
      // this.loading = true;
      // let { data } = await this.$axios.post("/search/themes");
      // if (data.success) {
      //   this.categories = data.value.items;
      // }
      // //   this.checkActiveCategory();
      // this.loading = false;
    },
  },
  async created() {
    this.categories = this.categories.filter((cat) => cat.is_active);
  },
};
</script>

<style lang="scss">
.home-categories {
  padding: 0px 10px;
  position: sticky;
  top: 0px;
  z-index: 99;
  

  .skeleton-img {
    width: 80px;
    height: 80px;
    border-radius: 50%;
  }
  .category-img {
    span {
      transition: 0.4s;
    }
  }
  .custom-slick-arrow {
    z-index: 9;
    &--prev {
      left: -8px;
    }
    &--next {
      right: -8px;
    }
  }
  .slick-slider {
    padding: 10px !important;
    width: 100%;
  }

  @media (max-width: 1024px) {
    .custom-slick-arrow {
      width: 20px;
      height: 20px;
    }
    .slick-slider {
      padding: 20px 0px;
    }
  }
}
</style>
