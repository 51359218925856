<template>
  <div class="categories-cards" v-if="categories.length > 0">
    <div class="max-width">
      <v-row>
        <v-col
          lg="3"
          md="4"
          cols="6"
          v-for="(category, i) in categories"
          :key="i"
        >
          <div class="relative">
            <div class="not-active-message" v-if="!category.is_active">
              <span>{{ $t("global.noCategory") }}</span>
            </div>
            <div :class="category.is_active ? '' : 'opacity-3'">
              <div
                class="categories-cards__card"
                @click="$router.push('/category/' + category.slug)"
              >
                <div
                  v-if="category.tag"
                  class="card__tag mx-auto d-flex align-center justify-center text-center"
                  :class="
                    category.tag.shape == 'circle' ? 'card__tag__circle' : ''
                  "
                  :style="{ background: category.tag.background }"
                >
                  <span
                    class="font-35"
                    :style="{ color: category.tag.color }"
                    >{{ category.tag.name }}</span
                  >
                </div>
                <div
                  class="categories-cards__card__layout d-flex align-center pa-4 text-center"
                >
                  <span class="white--text font-24 font-600 uppercase">{{
                    category.name
                  }}</span>
                </div>
                <img :src="category.image" alt="" />
              </div>
            </div>
          </div>
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    categories: {
      type: Array,
      default: () => [],
    },
  },
};
</script>

<style lang="scss">
.categories-cards {
  margin: 5% 0px;
  &__card {
    position: relative;
    height: 300px;
    border-radius: 10px;
    overflow: hidden;
    cursor: pointer;

    &__layout {
      position: absolute;
      left: 0px;
      width: 100%;
      height: 100%;
      background: rgba(0, 0, 0, 0.3);
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 10px;
      opacity: 1;
      transition: 0.3s;
      bottom: 0px;
      z-index: 3;
    }
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      transition: 0.3s;
    }
    &:hover {
      img {
        transform: scale(1.2);
      }
     
    }
  }

  @media (max-width: 960px) {
    &__card {
      height: 150px;
    }
  }
}
</style>
