<template>
  <div class="homepage-hero relative" v-if="slider.length > 0">
    <slick-carousel
      class="large-slide"
      ref="c1"
      :asNavFor="c2"
      v-bind="largeSlide"
    >
      <div
        class="large-slide__item"
        :style="{ background: isMd ? '' : item.background }"
        v-for="(item, index) in slider"
        :key="index"
      >
        <img
          class="object-cover large-slide__item__img"
          width="100%"
          height="100%"
          :src="isMd ? item.image_phone: item.image "
        />
      </div>
      <template #prevArrow>
        <v-icon
          class="custom-slick-arrow custom-slick-arrow--prev cursor-pointer"
          >mdi-chevron-left</v-icon
        >
      </template>
      <template #nextArrow>
        <v-icon
          class="custom-slick-arrow custom-slick-arrow--next cursor-pointer"
          >mdi-home</v-icon
        >
      </template>
    </slick-carousel>

    <div class="max-width" v-if="!isMd">
      <slick-carousel
        ref="c2"
        :asNavFor="c1"
        v-bind="smallSlide"
        class="small-slide"
      >
        <div
          class="small-slide__item"
          v-for="(item, index) in slider"
          :key="index"
        >
          <img
            class="object-cover"
            width="100%"
            height="100%"
            :src="item.image"
          />
        </div>
      </slick-carousel>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    slider: {
      type: Array,
      default: () => [],
    },
  },
  data: () => ({
    largeSlide: {
      touchMove: false,
      speed: 1000,
      autoplaySpeed: 4000,
      slidesToShow: 1,
      autoplay: true,
      dots: false,
      focusOnSelect: true,
      fade: true,
      responsive: [
        {
          breakpoint: 960,
          settings: {
            arrows: false,
            autoplay: true,
          },
        },
      ],
    },
    smallSlide: {
      autoplay: false,
      touchMove: false,
      speed: 1000,
      autoplaySpeed: 4000,
      slidesToShow: 6,
      focusOnSelect: true,
      slidesToScroll: 1,
    },

    c1: undefined,
    c2: undefined,
    selectedImage: 0,
    sliderUpdated: true,
  }),
  methods: {
    changeSlider(event) {
      console.log("event");
      this.sliderUpdated = !this.sliderUpdated;
      this.selectedImage = event;
    },
  },

  mounted() {
    setTimeout(() => {
      this.c1 = this.$refs.c1;
      this.c2 = this.$refs.c2;
    }, 500);
  },
};
</script>

<style lang="scss">
.homepage-hero {
  &__bg {
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
  }

  .large-slide__item {
    transition: 0.3s;
    padding: 30px 0px 150px 0px;
  }
  .large-slide {
    height: 780px;
    &__item {
      &__img {
        width: 85%;
        max-width: 1300px;
        margin: auto;
        border-radius: 5px;
      }
    }

    div {
      height: 100%;
      width: 100%;
    }
  }

  .small-slide {
    position: absolute;
    bottom: 10px;
    width: 92%;
    .slick-slide {
      padding: 10px;

      div {
        height: 110px;
      }
    }
    &__item {
      height: 110px;
      border: 1px solid white;
      overflow: hidden;
      border-radius: 10px;
      width: 100%;
      cursor: pointer;
    }
  }
  .custom-slick-arrow {
    z-index: 9;
    top: 45%;
    width: 50px;
    height: 50px;
    border-radius: 5px;
    background-color: rgba(0, 0, 0, 0.36);
    position: absolute;
    &--prev {
      left: 3%;
    }
    &--next {
      right: 3%;
    }
  }
  @media (max-width: 960px) {
    .large-slide {
      height: 400px;
      &__item {
        &__img {
          width: 100%;
        }
      }
    }
    .large-slide__item {
      transition: 0.3s;
      padding:  0px;
    }
    .slick-dots {
      padding: 0px;
      li,
      button {
        width: 16px;
        height: 16px;
        border-radius: 50%;
      }
      button{
        &::before{
          background: rgb(177, 177, 177);
          border-radius: 50%;
          color: transparent
        }
      }
    }
    .small-slide {
      margin-top: 20px;

      .slick-slide {
        padding: 10px;
        height: 55px;
        div {
          height: 100%;
        }
      }
      .slick-slide {
        padding: 3px;
        img {
          height: 100%;
        }
      }
    }
  }

  

}
</style>
