<template>
  <div>
    <div class="max-width my-14">
      <GmapMap
      :center="{ lat: +locationDetails.lat, lng: +locationDetails.lng }"
      :zoom="16"
      map-type-id="terrain"
      :style="`width: 100%; height: ${isMd ? '300px': '500px'}`"
    >
      <GmapMarker
        :key="index"
        :position="{ lat: +locationDetails.lat, lng: +locationDetails.lng }"
        :clickable="true"
        :draggable="true"
      />
    </GmapMap>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
export default {
  data() {
    return {
    };
  },
  computed:{
    ...mapGetters(['locationDetails'])
  }
};
</script>

<style lang="scss">
.home-map {
  border-radius: 10px;
  overflow: hidden;
  margin: 8% 0px;

}
</style>
